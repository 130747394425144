/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/util';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

@import '../../style/abstract/variables/font_awesome';
@import '../../style/abstract/variables/icon_font';
@import '../../style/abstract/button_extend';
@import '../../style/abstract/icon_font';
@import '../../style/abstract/typography';
@import '../../style/abstract/input';
@import '../../style/abstract/block';
@import '../../style/abstract/volume_pricing_guide';

.CreditCardForm {
    &-Logos {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;

        & + .Field-Wrapper {
            width: 100%;
        }
    }

    &-Logo {
        width: 42px;
        height: 24px;
        margin-inline-end: 5px;

        &:last-child {
            margin-inline-end: 0;
        }
    }

    &-Number, &-BillingName {
        input {
            width: 100%;
        }
    }

    &-DateFields, &-BillingNameFields {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        column-gap: 10px;

        > .Field-Wrapper {
            width: calc(50% - 5px);
        }

        > .Field-ErrorMessages {
            width: 100%;
        }

        & + .Field-Wrapper {
            width: 100%;

            input {
                width: 100%;
            }
        }
    }

    &-BillingNameFields {
        input {
            width: 100%;
        }
    }

    &-CvvRow {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        column-gap: 10px;

        .CreditCardForm {
            &-CvvWrapper {
                max-width: 130px;

                input {
                    width: 130px;
                }
            }

            &-BillingPostcodeWrapper {
                width: 100%;

                input {
                    width: 100%;
                }
            }
        }
    }

    &-Buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-block-start: 24px;
    }
}
