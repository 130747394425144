/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/util';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

@import '../../style/abstract/variables/font_awesome';
@import '../../style/abstract/variables/icon_font';
@import '../../style/abstract/button_extend';
@import '../../style/abstract/icon_font';
@import '../../style/abstract/typography';
@import '../../style/abstract/input';
@import '../../style/abstract/block';
@import '../../style/abstract/volume_pricing_guide';

.Popup {
    [dir="ltr"] & {
        inset: 50% unset unset 50%;
        transform: translate(-50%, -50%);
        z-index: 803;
        @include box-shadow(4px 4px 8px rgba(0, 0, 0, 0.2));

        &-Heading {
            @include h3-style;
        }

        &-Content {
            width: 100%;
            height: 100%;
            padding: 20px 20px 0;
            overflow-y: unset;

            @include border-radius(0);

            &::after {
                content: unset;
                padding-block-end: unset;
                display: none;
            }
        }

        &-CloseBtn {
            .CloseIcon {
                width: var(--close-icon-width);
                height: var(--close-icon-height);
            }
        }

        &-Mask {
            @include full-body-mask(802);
        }
    }
}

@include desktop {
    .Popup {
        [dir="ltr"] & {
            &-Heading {
                height: var(--popup-heading-height-desktop);
                margin: var(--popup-heading-margin-desktop);
                font-size: var(--popup-heading-font-size-desktop);
                font-weight: var(--popup-heading-font-weight-desktop);
                font-style: var(--popup-heading-font-style-desktop);
                line-height: var(--popup-heading-line-height-desktop);
                color: var(--popup-heading-color-desktop);
            }

            &-CloseBtn {
                inset: -10px -10px unset unset;
            }
        }
    }
}

@include mobile {
    .Popup {
        [dir="ltr"] & {
            width: 80%;
            height: auto;

            &-Header {
                display: block;
                width: 100%;
            }

            &-Heading {
                height: var(--popup-heading-height-mobile);
                margin: var(--popup-heading-margin-mobile);
                font-size: var(--popup-heading-font-size-mobile);
                font-weight: var(--popup-heading-font-weight-mobile);
                font-style: var(--popup-heading-font-style-mobile);
                line-height: var(--popup-heading-line-height-mobile);
                color: var(--popup-heading-color-mobile);
            }

            &-Content {
                height: auto;

                > * {
                    display: block;
                }
            }

            &-CloseBtn {
                inset: -10px -10px unset unset;
            }
        }
    }
}
