/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/util';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

@import '../../style/abstract/variables/font_awesome';
@import '../../style/abstract/variables/icon_font';
@import '../../style/abstract/button_extend';
@import '../../style/abstract/icon_font';
@import '../../style/abstract/typography';
@import '../../style/abstract/input';
@import '../../style/abstract/block';
@import '../../style/abstract/volume_pricing_guide';

:root {
    --required-text-color: var(--color-red);
}

.Field {
    &_type {
        &_text,
        &_textarea,
        &_email,
        &_password,
        &_date,
        &_time,
        &_datetime,
        &_datetime-local,
        &_file,
        &_number,
        &_select {
            &.Field_isValid {
                input,
                textarea,
                select {
                    border: 1px solid var(--color-light-gray);
                }
            }

            &.Field_isDisabled {
                .Field-Label {
                    color: var(--disabled-color);
                }

                input, select, textarea {
                    border: 1px solid var(--disabled-color);
                    color: var(--disabled-color);
                }
            }

            &.Small {
                input, select, textarea {
                    height: 39px;
                    padding: 7px 7px 5px;
                    font-size: 15px;
                }
            }
        }

        &_number {
            width: fit-content;

            &.Field_hasError {
                &::after {
                    inset-inline-end: -25px;
                }
            }
        }

        &_radio {
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        &_text, &_password {
            .fa-eye, .fa-eye-slash {
                cursor: pointer;
                user-select: none;
                position: absolute;
                margin-inline-start: -40px;
                margin-block-start: 12px;
                color: var(--color-medium-gray);
                transition: all .5s;

                &:hover {
                    color: var(--color-medium-blue)
                }
            }
        }
    }

    &_usesFloatingLabel {
        position: relative;

        /** label position for fields using 'floating labels' */
        .Field-LabelContainer {
            position: absolute;
            z-index: 1;
            transform: translate(15px, 12px) scale(1);
            transform-origin: top left;
            transition: all 0.2s ease-out;
            pointer-events: none;

            .Field-Label {
                z-index: 1;
                font-size: var(--input-font-size);
                padding: 0;
            }
        }

        &.float-label {
            .Field-LabelContainer {
                transform: translate(15px, 5px) scale(0.70);
            }

            &.Small {
                .Field-LabelContainer {
                    transform: translate(7px, 5px) scale(0.60);
                }

                input {
                    padding: 19px 7px 5px;
                }
            }
        }

        &:not(.float-label) {
            &.Small {
                .Field {
                    &-LabelContainer {
                        transform: translate(7px, 10px) scale(1);
                    }

                    &-Label {
                        font-size: 15px;
                    }
                }
            }
        }

        input, select, textarea {
            padding: 15px 15px 5px;

            &::placeholder {
                opacity: 0;
            }
        }
    }

    [type='checkbox'], [type='radio'] {
        &:not(:checked) {
            & + .input-control {
                border-width: 1px;
            }
        }

        &:hover, &:checked {
            &:not(:disabled) {
                & + .input-control {
                    --box-color: var(--color-white);

                    &::after {
                        --checkmark-color: var(--link-hover);
                    }
                }
            }

            &:not(:checked) {
                & + .input-control {
                    border-width: 2px;

                    &[disabled] {
                        border-color: var(--disabled-color);
                    }
                }
            }

            & + .input-control {
                &[disabled] {
                    border-color: var(--disabled-color);

                    &::after {
                        --checkmark-color: var(--disabled-color);
                    }
                }
            }
        }
    }

    [type='radio'] {
        &:checked,
        &:hover,
        &:focus {
            &:not(:disabled) {
                + .input-control {
                    background-color: var(--checkbox-border-color-active);

                    &::after {
                        --checkmark-color: transparent !important;
                    }
                }
            }

            &:disabled {
                + .input-control {
                    background-color: var(--disabled-color);

                    &::after {
                        --checkmark-color: transparent !important;
                    }
                }
            }
        }

        + .input-control {
            height: 20px;
            width: 20px;
            min-width: unset;
            max-width: unset;
            min-height: unset;
            max-height: unset;

            @include mobile {
                height: 16px;
                width: 16px;
            }
        }
    }

    &-CheckboxLabel {
        &:hover {
            color: var(--link-hover);

            &:not([class*='_isDisabled']) {
                color: var(--link-hover);
            }
        }
    }

    &-RadioLabel {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    &_hasError {
        /** error icon */
        @include icon-font($icon-error, var(--primary-error-color), 20px, $fa-font-weight-solid, after);

        &::after {
            /** error icon position */
            position: absolute;
            inset-block-end: 14px;
            inset-inline-end: 15px;
        }

        &.Field {
            &::after {
                display: none;
            }

            &_type_checkbox {
                .input-control {
                    border-width: 2px !important;
                    border-color: var(--primary-error-color);
                }
            }
        }
    }

    &-ErrorMessages {
        > .Field-ErrorMessage {
            display: inline-block;
        }
    }
}
